@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base styles */
@layer base {
  body {
    @apply bg-background-primary text-text-primary;
  }

  h1 {
    @apply text-4xl font-bold;
  }

  h2 {
    @apply text-3xl font-bold;
  }

  h3 {
    @apply text-2xl font-bold;
  }

  h4 {
    @apply text-xl font-bold;
  }
}

/* Component utility classes */
@layer components {
  .btn {
    @apply px-4 py-2 rounded font-medium transition-colors duration-200;
  }

  .btn-primary {
    @apply bg-primary hover:bg-primary-dark text-white;
  }

  .btn-secondary {
    @apply bg-secondary hover:bg-secondary-dark text-white;
  }

  .btn-outline {
    @apply border-2 border-primary text-primary hover:bg-primary hover:text-white;
  }

  .card {
    @apply bg-white rounded-lg shadow-md p-6;
  }

  .input {
    @apply w-full px-4 py-2 rounded border border-secondary-light focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent;
  }

  .container-padding {
    @apply px-4 md:px-6 lg:px-8;
  }
}

/* Layout utility classes */
@layer utilities {
  .page-container {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }

  .flex-between {
    @apply flex items-center justify-between;
  }
}
